// Definitions
import type { TFunction } from "i18next";

// Components
import { CellText } from "components/common/CellText";

export const getCookiesStatisticsColumns = (props: { t: TFunction }) => {
  const { t } = props;

  const CustomCell = (value: string) => CellText({ value, textProps: { size: "12" } });

  return [
    {
      title: t("cookies:consentCookies.columns.name"),
      dataIndex: "name",
      key: "name",
      width: 130,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.vendor"),
      dataIndex: "vendor",
      key: "vendor",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.expiry"),
      dataIndex: "expiry",
      key: "expiry",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.type"),
      dataIndex: "type",
      key: "type",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.description"),
      dataIndex: "description",
      key: "description",
      width: 280,
      render: CustomCell,
    },
  ];
};

export const getCookiesStatisticsData = (props: { t: TFunction }) => {
  const { t } = props;
  return [
    {
      key: "1",
      name: "_ga",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.ga"),
    },
    {
      key: "2",
      name: "_gid",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1day"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.gid"),
    },
    {
      key: "3",
      name: "_gat",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1minute"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.statistics.cookies.gat"),
    },
  ];
};
