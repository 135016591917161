// Core
import Script from "next/script";
import { withErrorBoundary } from "react-error-boundary";

// Definitions
import type { ReactElement } from "react";

// Components
import { ReactQueryDevtools } from "react-query/dist/react-query-devtools.production.min.js";
import { Page } from "components/common/Page";
import { ClientError } from "components/common/Errors";

// Utils
import { verifyEnvironment } from "utils/verify-environment";
import { verifyBuild } from "utils/verify-build";

type LayoutWrapperProps = {
  children: ReactElement;
};

export const LayoutCommonWithAnalytics = ({ children }: LayoutWrapperProps) => {
  const { isDevelopment } = verifyEnvironment();
  const buildEnv = verifyBuild();

  return (
    <>
      {buildEnv.isProduction && (
        <Script src="/kmtx/kmtx.js" id="kmtx-tracking" strategy="afterInteractive" />
      )}
      {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
      <Page>{children}</Page>
    </>
  );
};

export const LayoutCommon = withErrorBoundary(LayoutCommonWithAnalytics, {
  FallbackComponent: ClientError,
  onError(error, info) {
    console.warn("Error Boundary report.", "error:", error, "info:", info);
  },
});
