// Core
import cx from "classnames";

// Components
import { Grid, Table } from "antd";

// Definitions
import type { CommonTable } from "./TableAnt.types";

// Utils
import st from "./Styles.module.less";

enum TableAntTypeEnum {
  common = "common",
  description = "description",
  "products-listing" = "products-listing",
}

type TableAntProps<T> = CommonTable<T> & {
  type?: keyof typeof TableAntTypeEnum;
  testId?: string;
};

export const TableAnt = <T extends object>(props: TableAntProps<T>) => {
  const {
    dataSource = [],
    bordered = false,
    size = "middle",
    columns,
    withoutGutters = false,
    scroll,
    type = "",
    loading = false,
    pagination = false,
    cellAlign = "center",
    cellDisabled,
    expandable,
    testId,
    components,
    ...restProps
  } = props;

  const { md = true, lg = true } = Grid.useBreakpoint();

  const tableStyle = cx(st["table-wrapper"], {
    [st.mobile]: !md,
    [st.tablet]: !lg,
    [st["without-gutters"]]: withoutGutters,
    [st["table-cell-align-top"]]: cellAlign === "top",
    [st["table-cell-align-middle"]]: cellAlign === "middle",
    [st["table-cell-align-bottom"]]: cellAlign === "bottom",
    [st["table-cell-disabled"]]: cellDisabled,
    [st[`table-type-${type}`]]: type && TableAntTypeEnum[type],
  });
  const customCell =
    components?.body && "cell" in components.body ? components?.body?.cell : void 0;
  const customRow = components?.body && "row" in components.body ? components?.body?.row : void 0;

  return (
    <div className={tableStyle} data-test-id={testId}>
      <Table
        {...restProps}
        {...(customCell && {
          components: {
            body: {
              cell: customCell,
            },
          },
        })}
        {...(customCell &&
          customRow && {
            components: {
              body: {
                row: customRow,
                cell: customCell,
              },
            },
          })}
        rowKey="id"
        rowClassName="editable-row"
        bordered={bordered}
        size={size}
        dataSource={dataSource}
        columns={columns}
        scroll={scroll}
        loading={loading}
        pagination={pagination}
        expandable={expandable}
      />
    </div>
  );
};

TableAnt.EXPAND_COLUMN = Table.EXPAND_COLUMN;
TableAnt.Summary = Table.Summary;
