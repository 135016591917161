// Core
import cx from "classnames";
import { sanitize } from "dompurify";

// Components
import { Skeleton } from "antd";
import { WrapperNodeDefault } from "components/common/WrapperNodeDefault";
import { Text } from "components/ui/Typography/Text";

// Definitions
import type { ReactElement } from "react";
import type { TextProps } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";

type CellTextProps = {
  value?: string | number | null;
  postfix?: string;
  prefix?: string;
  loading?: boolean;
  disabled?: boolean;
  skeletonSize?: "large" | "small" | "default";
  testId?: string;
  textProps?: TextProps;
};

export const CellText = (props: CellTextProps): ReactElement | null => {
  const {
    value = null,
    postfix,
    prefix,
    loading,
    disabled,
    skeletonSize,
    testId,
    textProps,
  } = props;

  const cellStyles = cx(st["cell-percentage"], {
    [st.disabled]: disabled,
  });

  if (!value) {
    return (
      <div className={cellStyles} data-test-id={testId}>
        {loading ? (
          <Skeleton.Input active className={st.skeleton} size={skeletonSize} />
        ) : (
          WrapperNodeDefault(null, disabled)
        )}
      </div>
    );
  }

  return (
    <div className={cellStyles} data-test-id={testId}>
      {prefix && <span className={st.prefix}>{sanitize(prefix)} </span>}
      <Text {...textProps}>{value}</Text>
      {postfix && <span className={st.postfix}>{sanitize(postfix)}</span>}
    </div>
  );
};
