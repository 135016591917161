// Core
import type { FC, ReactElement } from "react";
import cx from "classnames";

// Components
import { Layout, Row, Col } from "antd";
import { Container } from "components/common/Container";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

type LayoutContentProps = {
  children: ReactElement | ReactElement[];
  intro?: boolean;
  noGutters?: boolean;
  hasPrivate?: boolean;
  hasNavigate?: boolean;
};

export const LayoutContent: FC<LayoutContentProps> = (props) => {
  const {
    children = null,
    intro = false,
    noGutters = false,
    hasPrivate = false,
    hasNavigate = false,
  } = props;

  const innerStyles = cx(st.inner, {
    [st["no-gutters"]]: noGutters,
    [st.private]: hasPrivate,
    [st.navigate]: hasNavigate,
  });

  return (
    <Layout.Content className={st.main}>
      {intro ? (
        <div className={innerStyles}>{children}</div>
      ) : (
        <Container>
          <Row gutter={rowGutters} className={st.row}>
            <Col span={24}>
              <div className={innerStyles}>{children}</div>
            </Col>
          </Row>
        </Container>
      )}
    </Layout.Content>
  );
};
