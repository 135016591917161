// Core
import { useContext, useEffect } from "react";
import dayjs from "dayjs";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Utils
import { cookiesService } from "utils/cookies";
import { GET_ENUMS } from "utils/constants";
import { isValidDate } from "utils/isValidDate";
import { PermissionContext } from "contexts/usePermissionContext";
import { useModalContext } from "hooks/useModalContext";

const SURVEY_LAST_SHOW_DATE_KEY = "survey_last_show_date";
const SURVEY_AMOUNT_SHOW_DAYS_KEY = "survey_amount_show_days";

export const useProfileSurveyNPS = () => {
  const ability = useContext(PermissionContext);
  const { setOnPageModal } = useModalContext();

  useEffect(() => {
    const canShowNpsModal = ability.can(PERMISSION_ACT.READ, "nps_survey_modal");

    if (canShowNpsModal) {
      const amountOfSurveyShowDays = Number(cookiesService.get(SURVEY_AMOUNT_SHOW_DAYS_KEY));
      const lastShowDate = Number(cookiesService.get(SURVEY_LAST_SHOW_DATE_KEY));
      const isAmountOfDaysValid = amountOfSurveyShowDays ? amountOfSurveyShowDays < 3 : true;

      if (isAmountOfDaysValid && isValidDate(lastShowDate)) {
        setOnPageModal(GET_ENUMS.popup.static.surveyNps, { visible: true });

        const currentShowDaysAmount = amountOfSurveyShowDays
          ? Number(amountOfSurveyShowDays) + 1
          : 1;

        cookiesService.set(SURVEY_AMOUNT_SHOW_DAYS_KEY, String(currentShowDaysAmount));
        cookiesService.set(SURVEY_LAST_SHOW_DATE_KEY, String(dayjs().unix()));
      }
    }
  }, []);
};
