// Definitions
import type { TFunction } from "i18next";
import type { TabItemType } from "components/ui/Tabs/Tabs.types";

// Components
import { CellTabPane } from "components/common/CellTabPane";
import { TabChildren } from "components/common/TabChildren";
import { ConsentCookiesNecessaryList } from "client/core/consent-cookies/components/consent-cookies-necessary-list";
import { ConsentCookiesStatisticsList } from "client/core/consent-cookies/components/consent-cookies-statistics-list";

type GetConsentCookiesItemsProps = {
  t: TFunction;
};

export const getConsentCookiesItems = (props: GetConsentCookiesItemsProps): TabItemType[] => {
  const { t } = props;

  const childrenNecessary = TabChildren({
    Content: ConsentCookiesNecessaryList,
  });
  const labelNecessary = CellTabPane({
    label: t<string>("consentCookies.necessary.tabTitle"),
  });

  const childrenStatistics = TabChildren({
    Content: ConsentCookiesStatisticsList,
  });
  const labelStatistics = CellTabPane({
    label: t<string>("consentCookies.statistics.tabTitle"),
  });

  return [
    {
      key: "1",
      disabled: false,
      children: childrenNecessary,
      label: labelNecessary,
    },
    {
      key: "2",
      disabled: false,
      children: childrenStatistics,
      label: labelStatistics,
    },
  ];
};
