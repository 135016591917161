// Core
import { z } from "zod";

export enum FormConsentCookiesEnum {
  necessary = "necessary",
  statistics = "statistics",
}

const i18NextPath = "cookies:";

export const inputConfig = {
  [FormConsentCookiesEnum.necessary]: {
    label: `${i18NextPath}consentCookies.inputs.necessaryCookies.label`,
  },
  [FormConsentCookiesEnum.statistics]: {
    label: `${i18NextPath}consentCookies.inputs.statisticsCookies.label`,
  },
};

export const consentCookiesForm = {
  shape: {
    [FormConsentCookiesEnum.necessary]: false,
    [FormConsentCookiesEnum.statistics]: false,
  },
  schema: () =>
    z.object({
      [FormConsentCookiesEnum.necessary]: z.boolean(),
      [FormConsentCookiesEnum.statistics]: z.boolean(),
    }),
};
