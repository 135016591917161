// Definitions
import type { TFunction } from "i18next";

// Components
import { CellText } from "components/common/CellText";

// Utils
import { CONSENT_COOKIE_KEY } from "client/core/consent-cookies/constants";

export const getCookiesNecessaryColumns = (props: { t: TFunction }) => {
  const { t } = props;

  const CustomCell = (value: string) => CellText({ value, textProps: { size: "12" } });

  return [
    {
      title: t("cookies:consentCookies.columns.name"),
      dataIndex: "name",
      key: "name",
      width: 130,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.vendor"),
      dataIndex: "vendor",
      key: "vendor",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.expiry"),
      dataIndex: "expiry",
      key: "expiry",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.type"),
      dataIndex: "type",
      key: "type",
      width: 100,
      render: CustomCell,
    },
    {
      title: t("cookies:consentCookies.columns.description"),
      dataIndex: "description",
      key: "description",
      width: 280,
      render: CustomCell,
    },
  ];
};

export const getCookiesNecessaryData = (props: { t: TFunction }) => {
  const { t } = props;
  return [
    {
      key: "1",
      name: "__Secure-next-auth.session-token",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthSession"),
    },
    {
      key: "2",
      name: "__Secure-next-auth.callback-url ",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthCallback"),
    },
    {
      key: "3",
      name: "__Host-next-auth.csrt-token",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.secureNextAuthCsrt"),
    },
    {
      key: "4",
      name: "kmtx_sync_pi",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.kmtxSyncPi"),
    },
    {
      key: "5",
      name: "kmtx_sync",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1year"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.kmtxSync"),
    },
    {
      key: "6",
      name: "sntk_test",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.1day"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.sntkTest"),
    },
    {
      key: "7",
      name: "VISITOR_INFO1_LIVE",
      vendor: "youtube.com",
      expiry: t("cookies:consentCookies.cookiesMaxAge.5months27days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.visitorInfoLive"),
    },
    {
      key: "8",
      name: "YSC",
      vendor: "youtube.com",
      expiry: t("cookies:consentCookies.cookiesMaxAge.session"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.ysc"),
    },
    {
      key: "9",
      name: "NEXT_LOCALE",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.nextLocale"),
    },
    {
      key: "10",
      name: CONSENT_COOKIE_KEY,
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.cookieConsent"),
    },
    {
      key: "11",
      name: "user-country",
      vendor: "autodoc.pro",
      expiry: t("cookies:consentCookies.cookiesMaxAge.30days"),
      type: "HTTP-Cookie",
      description: t("cookies:consentCookies.necessary.cookies.userCountry"),
    },
  ];
};
