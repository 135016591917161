// Core
import { useCallback, useEffect, useState } from "react";

// Definitions
import type { ConsentCookies } from "client/core/consent-cookies/models";
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";

// Utils
import { cookiesService } from "utils/cookies";
import { CONSENT_COOKIE_KEY } from "client/core/consent-cookies/constants";
import { gtmService } from "client/utils/gtm";

type UseConsentCookiesType = {
  isOpenConsentCookies: boolean;
  onAllowAllCookies: () => void;
  onRejectAllCookies: () => void;
  setConsentCookies: (data: ConsentCookies) => void;
};

const defaultConsentCookies: ConsentCookies = {
  necessary: false,
  statistics: false,
};

export const useConsentCookies = (): UseConsentCookiesType => {
  const [isOpenConsentCookies, setIsOpenConsentCookies] = useState(false);

  useEffect(() => {
    const consentCookies = cookiesService.fromJson<ConsentCookies>(CONSENT_COOKIE_KEY);
    const isAcceptedCookies = !!consentCookies;
    let cookieAppearanceTimeout: NodeJS.Timeout;

    gtmService.event(GTMTypesEnum.consent_mode_def, consentCookies || defaultConsentCookies);

    if (!isAcceptedCookies) {
      cookieAppearanceTimeout = setTimeout(() => setIsOpenConsentCookies(true), 2000);
    }

    return () => clearInterval(cookieAppearanceTimeout);
  }, []);

  const setConsentCookies = useCallback((data: ConsentCookies) => {
    cookiesService.toJson<ConsentCookies>(CONSENT_COOKIE_KEY, data);
    setIsOpenConsentCookies(false);
    gtmService.event(GTMTypesEnum.consent_mode_option, data);
  }, []);

  const onAllowAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
      statistics: true,
    });
  }, []);

  const onRejectAllCookies = useCallback(() => {
    setConsentCookies({
      ...defaultConsentCookies,
      necessary: true,
    });
  }, []);

  return {
    isOpenConsentCookies,
    onAllowAllCookies,
    onRejectAllCookies,
    setConsentCookies,
  };
};
