// Core
import variables from "./Styles.module.less";

export const modalTypes: {
  [key: string]: string;
} = {
  SEARCH_NOTICE: "SEARCH_NOTICE",
  CUSTOMER_CAR: "CUSTOMER_CAR",
  TRACKING_ORDER: "TRACKING_ORDER",
};

export const getModalWidth = (type?: string, autoWidth?: boolean): string => {
  if (autoWidth) {
    return variables.modalAutoWidth;
  }
  if (type === modalTypes.SEARCH_NOTICE) {
    return variables.modalSearchWidth;
  }
  if (type === modalTypes.CUSTOMER_CAR) {
    return variables.modalVehicleWidth;
  }
  if (type === modalTypes.TRACKING_ORDER) {
    return variables.modalTrackWidth;
  }
  return variables.modalDefaultWidth;
};
