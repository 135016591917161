// Domains
import { useProfileSurveyNPS } from "client/core/profile/hooks/useProfileSurveyNPS";

// Components
import { Modal } from "components/ui/Modal";
import { Can } from "components/common/Can";
import { ModalSurveyNps } from "components/common/ModalViews/SurveyNps";

// Definitions
import { PERMISSION_ACT } from "client/core/profile/models";

// Utils
import { useModalContext } from "hooks/useModalContext";
import { GET_ENUMS } from "utils/constants";

export const SurveyNps = () => {
  const { onPageModals, setOnPageModal } = useModalContext();

  const isSurveyNpsModalOpen = onPageModals[GET_ENUMS.popup.static.surveyNps]?.visible;

  const handleCloseModal = () => {
    setOnPageModal(GET_ENUMS.popup.static.surveyNps, { visible: false });
  };

  useProfileSurveyNPS();

  return (
    <Can I={PERMISSION_ACT.READ} this="nps_survey_modal">
      <Modal
        visible={isSurveyNpsModalOpen}
        onClose={handleCloseModal}
        wrapClassName="survey-wrapper"
        gutter="none"
        autoWidth
      >
        <ModalSurveyNps />
      </Modal>
    </Can>
  );
};
