// Core
import { useTranslation } from "next-i18next";

// Components
import { ConsentCookiesList } from "client/core/consent-cookies/components/consent-cookies-list-view";

// Utils
import {
  getCookiesStatisticsData,
  getCookiesStatisticsColumns,
  // eslint-disable-next-line max-len
} from "client/core/consent-cookies/components/consent-cookies-statistics-list/utils/render-models/consent-cookies-statistics-model";

export const ConsentCookiesStatisticsList = () => {
  const { t } = useTranslation();

  const data = getCookiesStatisticsData({ t });
  const columns = getCookiesStatisticsColumns({ t });

  const description = t("cookies:consentCookies.statistics.description");
  const listTitle = t("cookies:consentCookies.listOfCookies");

  return (
    <ConsentCookiesList
      columns={columns}
      data={data}
      description={description}
      listTitle={listTitle}
    />
  );
};
