// Definitions
import type { ReactElement } from "react";

// Domains
// import { useGoCoreLaunchPromotion } from "client/core/go-core/hooks/use-go-core-launch-promotion";

// Components
import { LayoutApp } from "components/common/LayoutApp";
import { LayoutContent } from "components/common/LayoutContent";
import { LayoutFooterPrivate } from "components/common/LayoutFooterPrivate";
import { LayoutHeaderPrivate } from "components/common/LayoutHeaderPrivate";
import { LayoutHeaderCart } from "components/common/LayoutHeaderCart";
import { ConsentCookies } from "client/core/consent-cookies";
import { SurveyNps } from "components/common/SurveyNps";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";

const ModalDynamic = DynamicImport(import("../Modal"), "Modal");

type LayoutPrivateProps = {
  children: ReactElement;
  hasNavigate?: boolean;
  hasPrivate?: boolean;
  hasCart?: boolean;
  hasMandate?: boolean;
  hasPaymentAdyen?: boolean;
  hasIntro?: boolean;
};

export const LayoutPrivate = (props: LayoutPrivateProps) => {
  const { children, hasPrivate, hasCart, hasMandate, hasPaymentAdyen, hasIntro, hasNavigate } =
    props;

  // useGoCoreLaunchPromotion();

  return (
    <LayoutApp type="private">
      {hasCart ? (
        <LayoutHeaderCart hasMandate={hasMandate} hasPaymentAdyen={hasPaymentAdyen} />
      ) : (
        <LayoutHeaderPrivate />
      )}
      <LayoutContent
        hasPrivate={hasPrivate}
        hasNavigate={hasNavigate}
        noGutters={hasIntro}
        intro={hasIntro}
      >
        {children}
      </LayoutContent>
      <LayoutFooterPrivate />
      <ConsentCookies />
      <SurveyNps />
      <ModalDynamic />
    </LayoutApp>
  );
};
