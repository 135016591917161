// Definitions
import type { ReactElement } from "react";
import type { ButtonsBecomeType } from "components/common/ButtonsBecome/ButtonsBecome.types";

// Components
import { LayoutApp } from "components/common/LayoutApp";
import { LayoutContent } from "components/common/LayoutContent";
import { LayoutFooterPublic } from "components/common/LayoutFooterPublic";
import { LayoutHeaderPublic } from "components/common/LayoutHeaderPublic";
import { ConsentCookies } from "client/core/consent-cookies";
import { DynamicImport } from "components/common/DynamicImport/DynamicImport";

const ModalDynamic = DynamicImport(import("../Modal"), "Modal");

type LayoutPublicProps = {
  children: ReactElement;
  navigationType?: ButtonsBecomeType;
  hasNavigate?: boolean;
  hasPrivate?: boolean;
  hasIntro?: boolean;
  hasCountrySelector?: boolean;
  typeHeader?: "ghost";
};

export const LayoutPublic = (props: LayoutPublicProps) => {
  const {
    children,
    navigationType,
    hasPrivate,
    hasIntro,
    hasNavigate,
    hasCountrySelector,
    typeHeader,
  } = props;

  return (
    <LayoutApp type="public">
      <LayoutHeaderPublic
        navigationType={navigationType}
        hasCountrySelector={hasCountrySelector}
        typeHeader={typeHeader}
      />
      <LayoutContent
        hasPrivate={hasPrivate}
        hasNavigate={hasNavigate}
        noGutters={hasIntro}
        intro={hasIntro}
      >
        {children}
      </LayoutContent>
      <LayoutFooterPublic hasCountrySelector={hasCountrySelector} />
      <ConsentCookies />
      <ModalDynamic />
    </LayoutApp>
  );
};
