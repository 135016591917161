type TitlesType = {
  [key: string]: string;
};

const getAlias = (pageKey: string): string => {
  const pathKey = "common:titles";
  return `${pathKey}.${pageKey}`;
};

export const pageTitles: TitlesType = {
  "/": getAlias("root"),
  "/404": getAlias("notFound"),
  "/500": getAlias("serverError"),
  "/signin": getAlias("signin"),
  "/about": getAlias("about"),
  "/signup": getAlias("signup"),
  "/signup/profile": getAlias("signup"),
  "/dashboard": getAlias("dashboard"),
  "/privacy-policy": getAlias("privacyPolicy"),
  "/payment": getAlias("payment"),
  "/delivery": getAlias("delivery"),
  "/term-conditions": getAlias("termConditions"),
  "/company-information": getAlias("companyInformation"),
  "/personal-manager": getAlias("personalManager"),
  "/catalog": getAlias("catalog"),
  "/catalog/[categoryId]": getAlias("catalog"),
  "/catalog/[categoryId]/[subCategoryId]": getAlias("products"),
  "/catalog/[categoryId]/[subCategoryId]/[productId]": getAlias("product"),
  "/catalog/new-catalog/new": getAlias("catalog"),
  "/catalog/new-catalog/new/categories/[categoryId]": getAlias("catalog"),
  "/catalog/new-catalog/new/categories/[categoryId]/sub-categories/[subCategoryId]":
    getAlias("products"),
  "/catalog/new-catalog/new/categories/[categoryId]/sub-child-categories/[subChildCategoryId]":
    getAlias("products"),
  "/catalog/new-catalog/new/categories/[categoryId]/sub-child-categories/[subChildCategoryId]/products/[productId]":
    getAlias("product"),
  "/catalog/new-catalog/new/categories/[categoryId]/sub-categories/[subCategoryId]/products/[productId]":
    getAlias("product"),
  "/catalog/new-catalog/[vehicleId]": getAlias("catalog"),
  "/catalog/new-catalog/[vehicleId]/categories/[categoryId]": getAlias("catalog"),
  "/catalog/new-catalog/[vehicleId]/categories/[categoryId]/sub-categories/[subCategoryId]":
    getAlias("products"),
  "/catalog/new-catalog/[vehicleId]/categories/[categoryId]/sub-child-categories/[subChildCategoryId]":
    getAlias("products"),
  // eslint-disable-next-line max-len
  "/catalog/new-catalog/[vehicleId]/categories/[categoryId]/sub-child-categories/[subChildCategoryId]/products/[productId]":
    getAlias("product"),
  "/catalog/new-catalog/[vehicleId]/categories/[categoryId]/sub-categories/[subCategoryId]/products/[productId]":
    getAlias("product"),
  "/catalog/new-catalog/new/products-search": getAlias("products"),
  "/catalog/new-catalog/new/products-search/[productId]": getAlias("product"),
  "/catalog/new-catalog/[vehicleId]/products-search": getAlias("products"),
  "/catalog/new-catalog/[vehicleId]/products-search/[productId]": getAlias("product"),
  "/catalog/new-catalog/new/products-oem": getAlias("products"),
  "/catalog/new-catalog/new/products-oem/[productId]": getAlias("product"),
  "/catalog/new-catalog/[vehicleId]/products-oem": getAlias("products"),
  "/catalog/new-catalog/[vehicleId]/products-oem/[productId]": getAlias("product"),
  "/settings/loyalty-program": getAlias("loyaltyProgram"),
  "/settings/company-address": getAlias("companyAddress"),
  "/settings/company-working-hours": getAlias("companyWorkingHours"),
  "/settings/representative-contact": getAlias("representativeContact"),
  "/settings/change-password": getAlias("changePassword"),
  "/settings/users": getAlias("users"),
  "/settings/payment-methods": getAlias("paymentMethods"),
  "/settings/deposit-account": getAlias("depositAccount"),
  "/settings/estimate-settings": getAlias("estimateSettings"),
  "/settings/language": getAlias("language"),
  "/cart/preview": getAlias("cartPreview"),
  "/cart/delivery": getAlias("cartDelivery"),
  "/mandate": getAlias("mandate"),
  "/core-exchange": getAlias("coreExchange"),
  "/benefits": getAlias("benefits"),
  "/notifications": getAlias("notifications"),
  "/personalManager": getAlias("personalManager"),
  "/customers": getAlias("customers"),
  "/customers/[id]": getAlias("customerDetails"),
  "/orders": getAlias("orders"),
  "/orders/[id]": getAlias("orderDetails"),
  "/orders/checkout": getAlias("orderCheckout"),
  "/cookies": getAlias("cookies"),
  "/saved-baskets": getAlias("savedBaskets"),
  "/saved-baskets/[id]": getAlias("savedBasketDetails"),
  "/returns": getAlias("returns"),
  "/returns/[id]": getAlias("returnDetails"),
  "/returns/new-confirm": getAlias("returnNewConfirm"),
  "/returns/new-draft": getAlias("returnNewDraft"),
  "/repair-estimates": getAlias("repairEstimates"),
  "/repair-estimates/[id]": getAlias("repairEstimatesDetails"),
  "/confirm-promo-subscription": getAlias("confirmPromoSubscription"),
  "/returns-and-refunds": getAlias("returnsAndRefunds"),
  "/catalog/graphic/[vehicleId]/categories": getAlias("catalogGraphic"),
  "/catalog/graphic/[vehicleId]/categories/[...categoryIds]": getAlias("categoryDetails"),
  "/catalog/graphic/[vehicleId]/schemas": getAlias("catalogGraphic"),
  "/catalog/graphic/[vehicleId]/schemas/[schemaId]": getAlias("schemeDetails"),
  "/catalog/graphic/[vehicleId]/schemas/[schemaId]/oem-groups/[oemGroupId]":
    getAlias("oemGroupDetails"),
  "/catalog/graphic/[vehicleId]/schemas/[schemaId]/oem-groups/[oemGroupId]/products/[productId]":
    getAlias("productDetails"),
  "/benefits-terms-conditions": getAlias("benefitsTermConditions"),
  "/documents": getAlias("documents"),
};
