// Core
import { cloneElement } from "react";
import cx from "classnames";

// Definitions
import { ModalProps as AntdModalProps } from "antd";

// Components
import { Modal as AntModal } from "antd";
import { Icon } from "components/ui/Icon";

// Utils
import st from "./Styles.module.less";
import { getModalWidth } from "./utils";

export type ModalProps = AntdModalProps & {
  children: React.ReactElement;
  gutter?: "none" | "small" | "middle" | "large";
  autoWidth?: boolean;
  visible?: boolean;
  isFullScreen?: boolean;
  isCentered?: boolean;
  onVisible?: () => void;
  onClose?: () => void;
  onHidden?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  modalType?: string;
};

export const Modal = (props: ModalProps) => {
  const {
    children,
    visible,
    onVisible,
    onHidden,
    onClose,
    isCentered,
    zIndex = 1000,
    wrapClassName = "",
    gutter = "large",
    autoWidth = false,
    modalType,
  } = props;
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.preventDefault();
    onClose?.();
    onHidden?.(e);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleClose(e);
    onHidden?.(e);
  };

  const modalWidth = getModalWidth(modalType, autoWidth);

  const modalStyle = cx(st.modal, {
    [st[`modal-gutter-${gutter}`]]: Boolean(gutter),
  });

  return (
    <AntModal
      className={modalStyle}
      width={modalWidth}
      open={visible}
      centered={isCentered ?? true}
      footer={null}
      destroyOnClose
      onOk={onVisible}
      onCancel={handleClose}
      closeIcon={<Icon name="ControlCloseModal" />}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
    >
      {cloneElement(children, { onClose: handleCancel })}
    </AntModal>
  );
};
