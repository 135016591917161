// Core
import { FC, ReactElement } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

// Utils
import { pageTitles } from "data/pages";
import { default as iconSpriteStaticImg } from "components/ui/Icon/assets/sprite.svg";

type PageProps = {
  children: ReactElement;
  title?: string;
};

export const Page: FC<PageProps> = (props) => {
  const { children, title } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const pageTitle = `${t("common:firstPartPageTitle")} | ${t(
    title || pageTitles[router.route] || "",
  )}`;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta
          name="viewport"
          content="width=device-width,
          initial-scale=1,
          shrink-to-fit=no, user-scalable=0"
        />
        <link as="image" href={iconSpriteStaticImg.src} />
      </Head>
      {children}
      <Script src="/genesys/cxbus.min.js" strategy="lazyOnload" />
      <Script src="/genesys/genesys.js" strategy="lazyOnload" />
    </>
  );
};
