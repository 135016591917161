// Components
import { TableAnt } from "components/ui/TableAnt";
import { Text } from "components/ui/Typography/Text";

// Definitions
import type { ColumnsTypeCustom } from "components/ui/TableAnt/TableAnt.types";

// Utils
import st from "./styles.module.less";

type ConsentCookiesListProps = {
  columns: ColumnsTypeCustom;
  data: Record<string, unknown>[];
  description: string;
  listTitle: string;
};

export const ConsentCookiesList = (props: ConsentCookiesListProps) => {
  const { columns, data, description, listTitle } = props;

  return (
    <div className={st["consent-cookies-list"]}>
      <div className={st.description}>
        <Text size="12">{description}</Text>
      </div>
      <div className={st["list-title"]}>
        <Text size="12" fontWeight="600">
          {listTitle}
        </Text>
      </div>
      <TableAnt columns={columns} dataSource={data} scroll={{ y: 175 }} />
    </div>
  );
};
