// Core
import dayjs from "dayjs";

export const isValidDate = (lastShowDate: number): boolean => {
  if (lastShowDate) {
    const currentDate = dayjs().unix();
    const minNextShowDate = dayjs.unix(lastShowDate).add(1, "day").unix();

    return currentDate >= minNextShowDate;
  }

  return true;
};
