// Core
import cx from "classnames";

// Components
import { Icon } from "components/ui/Icon";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";

type CellTabPaneProps = {
  type?: "default" | "primary" | "expand";
  label: string;
  description?: string;
  icon?: string;
  count?: number;
  active?: boolean;
};

export const CellTabPane = (props: CellTabPaneProps) => {
  const { label, description, icon, count, type = "default", active } = props;

  const iconStyles = cx(st["cell-tab-panel-icon"], {
    [st["cell-tab-panel-icon-active"]]: Boolean(active),
  });

  if (type === "default") {
    return (
      <div className={st["cell-tab-panel"]}>
        <div className={st["cell-tab-panel-main"]}>
          <Text
            box="full"
            size="14"
            font="system"
            fontWeight="400"
            alignmentVertical="middle"
            noColor
          >
            {label}
          </Text>
        </div>
      </div>
    );
  }

  if (type === "expand") {
    return (
      <div className={st["cell-tab-panel"]}>
        {icon && (
          <div className={iconStyles}>
            <Icon name={icon} size="small" />
          </div>
        )}
        <div className={st["cell-tab-panel-main"]}>
          <Text
            box="full"
            size="14"
            font="system"
            fontWeight="400"
            alignmentVertical="middle"
            {...(active ? { color: "orange-800" } : { color: "gray-500" })}
          >
            {label}
          </Text>
          {description && (
            <Text
              box="full"
              size="12"
              font="system"
              color="gray-500"
              fontWeight="400"
              alignmentVertical="middle"
            >
              {description}
            </Text>
          )}
        </div>
      </div>
    );
  }

  if (type === "primary") {
    return (
      <div className={st["cell-tab-panel"]}>
        <div className={st["cell-tab-panel-main"]}>
          <Text
            box="full"
            size="14"
            font="system"
            fontWeight="400"
            alignmentVertical="middle"
            noColor
          >
            {label}
          </Text>
        </div>

        {!!count && (
          <div className={st["cell-tab-panel-count"]}>
            <Text
              box="full"
              size="14"
              fontWeight="400"
              font="system"
              alignmentVertical="middle"
              noColor
            >
              {count}
            </Text>
          </div>
        )}
      </div>
    );
  }

  return null;
};
