// Core
import React, { FC } from "react";

type TabChildrenProps = Record<string, unknown> & {
  Content: FC;
};
export const TabChildren: FC<TabChildrenProps> = (props) => {
  const { Content, ...rest } = props;
  return <Content {...rest} />;
};
